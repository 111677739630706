<template>
<div class="page-warp container-page">
    <div class="row fix-top-title">
        <div class="row model-title page-model-t">
            <span class="t">政策解读</span>
        </div>
    </div>
    <div class="scroll-box pro-model pro-model-fix-t">
        <div class="row pro-model" v-for="(item,inx) in loopList" :key="inx">
            <div class="pro-model-chil" @click="todetail(item.id)">
                <div class="pro-model-item">
                    <div class="pro-model-text pr-10">
                        <div class="t">{{item.title}}</div>
                        <div class="d">{{item.createTime}}</div>
                    </div>
                    <div class="pro-model-img">
                        <div class="img-box radius-b5">
                            <img v-if="item.pictureUrl" :src="item.pictureUrl.split(',')[0]"/>
                            <img v-else src="../../assets/images/product.png" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <infinite-loading 
            v-if="!finished" 
            @infinite="onLoad"
            ref="infiniteLoading">
        </infinite-loading>
            <div v-else class="row no-datas">没有更多了</div>
    </div>
</div>
</template>

<script>
import { pageQuery } from "@/api/policy"
import InfiniteLoading from "vue-infinite-loading";
export default {
    name:'Policy',
    components: {
        InfiniteLoading
    },
    watch:{
    },
    data() {
        return {
            pageInfo:{
                phone:'',
                time:''
            },
            loading:false,
            finished:false,
            page:1,
            pageSize:10,
            loopList:[],
        };
    },
    computed: {},
    filters:{
    },
    created() {
    },
    mounted() {},
    methods: {
        todetail(res){
            this.$router.push({
            path:'/policyDetail',
            query:{
                id:res
            }
            })
        },
        async onLoad($state){
            let pramas={
                pageNum:this.page,
                pageSize:this.pageSize
            }
            this.loading = true;
            await pageQuery (pramas).then(res=>{
                // res.rows.map(item=>{
                //     item.activeNames = []
                //     this.loopList.push(item);
                // })


                this.loopList = this.loopList.concat(res.rows);
                if (res.total <= this.loopList.length) {
                    this.finished = true;
                }
                this.page +=1;
                this.loading = false;
                
                if(this.$refs.infiniteLoading) this.$refs.infiniteLoading.$emit('$InfiniteLoading:loaded'); //完成加载
            })
        },
    },
};
</script>
<style lang="less" scoped>

</style>